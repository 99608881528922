const tailwindBaseColors = {
  transparent: "transparent",
  black: "#000",
  white: "#fff",
  gray: [null, "#f7fafc", "#edf2f7", "#e2e8f0", "#cbd5e0", "#a0aec0", "#718096", "#4a5568", "#2d3748", "#1a202c"],
  red: [null, "#fff5f5", "#fed7d7", "#feb2b2", "#fc8181", "#f56565", "#e53e3e", "#c53030", "#9b2c2c", "#742a2a"],
  orange: [null, "#fffaf0", "#feebc8", "#fbd38d", "#f6ad55", "#ed8936", "#dd6b20", "#c05621", "#9c4221", "#7b341e"],
  yellow: [null, "#fffff0", "#fefcbf", "#faf089", "#f6e05e", "#ecc94b", "#d69e2e", "#b7791f", "#975a16", "#744210"],
  green: [null, "#f0fff4", "#c6f6d5", "#9ae6b4", "#68d391", "#48bb78", "#38a169", "#2f855a", "#276749", "#22543d"],
  teal: [null, "#e6fffa", "#b2f5ea", "#81e6d9", "#4fd1c5", "#38b2ac", "#319795", "#2c7a7b", "#285e61", "#234e52"],
  blue: [null, "#ebf8ff", "#bee3f8", "#90cdf4", "#63b3ed", "#4299e1", "#3182ce", "#2b6cb0", "#2c5282", "#2a4365"],
  indigo: [null, "#ebf4ff", "#c3dafe", "#a3bffa", "#7f9cf5", "#667eea", "#5a67d8", "#4c51bf", "#434190", "#3c366b"],
  purple: [null, "#faf5ff", "#e9d8fd", "#d6bcfa", "#b794f4", "#9f7aea", "#805ad5", "#6b46c1", "#553c9a", "#44337a"],
  pink: [null, "#fff5f7", "#fed7e2", "#fbb6ce", "#f687b3", "#ed64a6", "#d53f8c", "#b83280", "#97266d", "#702459"],
};

const tailwindExportedColors = {
  grayDark: tailwindBaseColors.gray[8],
  text: tailwindBaseColors.gray[8],
  background: tailwindBaseColors.white,
  primary: tailwindBaseColors.blue[7],
  primaryHover: tailwindBaseColors.blue[8],
  secondary: tailwindBaseColors.gray[6],
  muted: tailwindBaseColors.gray[3],
  success: tailwindBaseColors.green[3],
  info: tailwindBaseColors.blue[4],
  warning: tailwindBaseColors.yellow[3],
  danger: tailwindBaseColors.red[3],
  light: tailwindBaseColors.gray[1],
  dark: tailwindBaseColors.gray[8],
  textMuted: tailwindBaseColors.gray[6]
};

export const caraThemeColors = {
  ...tailwindExportedColors.colors,
  primary: tailwindBaseColors.orange[4],
  secondary: tailwindBaseColors.indigo[6],
  text: tailwindBaseColors.gray[3],
  heading: tailwindBaseColors.white,
  background: `#141821`,
  divider: tailwindBaseColors.gray[8],
  textMuted: tailwindBaseColors.gray[5],
  icon_brightest: tailwindBaseColors.white,
  icon_darker: tailwindBaseColors.gray[7],
  icon_darkest: tailwindBaseColors.gray[8],
  icon_red: tailwindBaseColors.red[6],
  icon_blue: tailwindBaseColors.blue[6],
  icon_orange: tailwindBaseColors.orange[5],
  icon_yellow: tailwindBaseColors.yellow[5],
  icon_pink: tailwindBaseColors.pink[5],
  icon_purple: tailwindBaseColors.purple[6],
  icon_green: tailwindBaseColors.green[5],
  modes: {
    light: {
      text: tailwindBaseColors.gray[8],
      heading: tailwindBaseColors.black,
      primary: tailwindBaseColors.orange[7],
      background: tailwindBaseColors.gray[1],
      divider: tailwindBaseColors.gray[2],
      textMuted: tailwindBaseColors.gray[6],
      icon_brightest: tailwindBaseColors.gray[2],
      icon_darker: tailwindBaseColors.gray[4],
      icon_darkest: tailwindBaseColors.gray[6],
    },
  },
};