import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { ContentDiv } from "../element/layout/ContentContainers";
import Check from "./Check";
import CallToAction from "./CallToAction";

const Container = styled(ContentDiv)`
  z-index: 0;
  //margin: 4rem auto;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
`;

const txt = [
  "You focus on game development",
  "Oberion creates your video game website",
  "Oberion handles all aspects of a website for you so you can focus on game development."
];

const HeroContent = ({triggerBuild}) => {
  return (
    <Container>
      <Typography variant="h2" gutterBottom align="center">{txt[1]}</Typography>
      {/*<Typography variant="h2" gutterBottom align="center">{txt[2]}</Typography>*/}
      <Typography variant="h5" gutterBottom align="center">{txt[0]}</Typography>

      <Check />
      <CallToAction triggerBuild={triggerBuild} />

    </Container>
  );
};
export default HeroContent;