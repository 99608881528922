import React from "react";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";

const Container = styled.div`
  margin: 2rem 0 1rem 0;
  padding: 3rem;
  border: #03dac4 solid 5px;
  border-radius: 0.5rem;
  width: 100%;
  
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;
const TextFieldSt = styled(TextField)`
  margin: 0 0 2rem 0;
  width: 31rem;
`;

const txt = ["Create your website now", "Create website"];

const CallToAction = ({ triggerBuild }) => {
  return (
    <Container>
      <Typography variant="h2" style={{ marginBottom: "2rem" }}>{txt[0]}</Typography>
      <TextFieldSt label="Required"
                   required
                   variant="filled"
                   defaultValue="https://store.steampowered.com/app/504230/Celeste/"
                   helperText="Paste your Steam page URL"
      />
      <Button variant="contained" color="secondary" size="large"
              onClick={triggerBuild}
      >
        {txt[1]}
      </Button>
    </Container>
  );
};

export default CallToAction;