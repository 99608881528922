import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import theme, { caraThemeBlue, contentMaxWidth } from "../theme";

const verticalSpacing = "3rem";
export const defaultPaper = {
  padding: `${verticalSpacing} 1.5rem`,
  margin: `${verticalSpacing} 0`,
  backgroundColor: theme.palette.background.paper,
};
export const listPaper = {
  margin: `${verticalSpacing} 0`,
  backgroundColor: theme.palette.background.paper,
};
export const defaultPaperNoBackground = {
  padding: "0",
  margin: `${verticalSpacing} 0`,
  elevation: 0,
};
export const illustrationPaper = {
  padding: "0",
  margin: `${verticalSpacing} 0`,
  backgroundColor: theme.palette.background.default,
};


export const ContentDiv = styled.div`
  max-width: ${contentMaxWidth}rem;
  margin: 0 auto;
  padding: 0 0.8rem; 
  /* --- 0.8 necessary for Fucking MUI bug x-axis overflow when using Grid container spacing --- */
`;

export const MainGrid = styled(Grid)`
  flex-basis: 64%;
  flex-grow: 1;
`;
export const SideGrid = styled(Grid)`
  flex-basis: 36%;
  flex-grow: 1;
`;
