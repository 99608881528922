import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { format, formatDistance, formatRelative, subDays } from 'date-fns'

const ConsoleContainer = styled.div`
  margin: 3rem 0;
  background-color: black;
  background-image: radial-gradient(
    rgba(0, 75, 0, 0.65), black 140%
  );
  border: solid grey 1px;
  width: 100%;
  height: 30rem;
  padding: 1rem;
  z-index: 10;
`;
const ConsoleContainer1 = styled.div`
  background-color: #0e1e24;
  border: solid white 1px;
  width: 100%;
  height: 30rem;
  padding: 2rem;
  z-index: 10;
`;

const ConsoleOutput = ({triggerFeedback}) => {

  const [log, setLog] = useState([""]);
  const [finished, setFinished] = useState(false);




  if (finished) {
    triggerFeedback();
  }

  const onclick1 = () => {
    const date = format(new Date(), "HH:mm:ss");
    let line = date + ": ";
    switch(log.length) {
      case 1:
        line = line + "Initating build";
        break;
      case 2:
        line = line + "Visiting Steam";
        break;
      case 3:
        line = line + "Creating markup";
        break;
      case 4:
        line = line + "Styling markup";
        break;
      case 5:
        line = line + "Site finished: Visit the site!";
        break;
      default:
        line = line + "DEFAULT BLOCK ++++++++++++";
    }
    const newLog = [...log, line];
    setLog(newLog);
    if(log.length === 5){
      console.log("triggerFeedback in ConsoleOutput");
      setFinished(true);
    }
  };

  return (
    <ConsoleContainer onClick={onclick1}>
      {log.map((element, index) => (
        <div key={index}>
          {element}
        </div>
      ))}
    </ConsoleContainer>
  );
};

ConsoleOutput.propTypes = {

};

export default ConsoleOutput;