import React from "react";
import * as PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import SocialMediaIcon from "./SocialMediaIcon";
import iconTwitter from "./icon/Twitter_Social_Icon_Rounded_Square_Color.min.svg";
import iconStackOverflow from "./icon/Twitter_Social_Icon_Rounded_Square_Color.min.svg";
import iconGitHub from "./icon/Twitter_Social_Icon_Rounded_Square_Color.min.svg";
import iconXing from "./icon/Twitter_Social_Icon_Rounded_Square_Color.min.svg";
import iconLinkedIn from "./icon/Twitter_Social_Icon_Rounded_Square_Color.min.svg";

export const CHANNELS = {
  TWITTER: {
    name: "Twitter",
    url: "https://twitter.com/ThomasJanUta",
    icon: iconTwitter,
  },
  STACKOVERFLOW: {
    name: "Stack Overflow",
    url: "https://stackoverflow.com/users/3689364/eliteraceelephant",
    icon: iconStackOverflow,
  },
  GITHUB: {
    name: "GitHub",
    url: "https://github.com/ThomasJanUta",
    icon: iconGitHub,
  },
  XING: {
    name: "XING",
    url: "https://www.xing.com/profile/ThomasJan_Uta",
    icon: iconXing,
  },
  LINKEDIN: {
    name: "LinkedIn",
    url: "",
    icon: iconLinkedIn,
  },
};

const SocialMediaButton = ({ channel, unpadded }) => {
  let IconButtonToReturn = null;

  if (unpadded === true) {
    // unpadded => display the bare icons without extra styling

      // handle all external links
      IconButtonToReturn = (
        <a href={channel.url} target="_blank" rel="noopener" aria-label={channel.name}>
          <SocialMediaIcon icon={channel.icon} caption={channel.name} />
        </a>
      );

  } else {
    /* --- Padding for other icons --- */
    IconButtonToReturn = (
      <IconButton
        aria-label={channel.name}
        href={channel.url} target="_blank" rel="noopener"
      >
        <SocialMediaIcon icon={channel.icon} caption={channel.name} />
      </IconButton>
    );
  }
  return (
    IconButtonToReturn
  );
};

SocialMediaButton.defaultProps = {
  unpadded: false,
};
SocialMediaButton.propTypes = {
  channel: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  unpadded: PropTypes.bool,
};

export default SocialMediaButton;