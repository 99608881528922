import React, { useState } from "react";
import Layout from "../components/element/layout/Layout";
import { HeroAnimated } from "../components/hero/Hero";
import HeroContent from "../components/home/HeroContent";
import SEO from "../components/element/seo/SEO";
import ConsoleOutput from "../components/console/ConsoleOutput";
import { ContentDiv } from "../components/element/layout/ContentContainers";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import { ButtonRoute } from "../components/navigation/InternalRoute";
import { Link as GatsbyLink } from "gatsby";
import Button from "@material-ui/core/Button";

const seo = {
  title: "OMG-Prototyp",
  description: "",
};

const IndexPage = () => {
  const [building, setBuilding] = useState(false);
  const [finished, setFinished] = useState(false);

  const triggerBuild = () => {
    console.log("triggerBuild");
    setBuilding(true);
  };
  const triggerFeedback = () => {
    console.log("triggerFeedback");
    setFinished(true);
  };


  let consoleDiv = null;
  if (building) {
    consoleDiv = (
      <ConsoleOutput triggerFeedback={triggerFeedback} />
    );
  }

  let button = null;
  if (finished) {
    button = (
      <Button variant="contained" color="secondary" size="large"
              component={GatsbyLink} to="/newSite">
        Visit new Site
      </Button>
    );
  }

  return (
    <Layout>
      <LayoutColumn>
        <SEO {...seo} />
        {/*<HeroAnimated />*/}
        <HeroContent triggerBuild={triggerBuild} />
        {consoleDiv}
        {button}
      </LayoutColumn>
    </Layout>
  );
};

export default IndexPage;
