import React from "react";
import { Helmet } from "react-helmet";
import * as PropTypes from "prop-types";

/**
 * Provides meta tags for each page
 * @see https://www.gatsbyjs.org/docs/add-seo-component/
 * @see https://reactgo.com/react-seo-helmet/
 * @see http://ogp.me/ open graph protocol
 *
 * Fix URLs
 * @see https://www.gatsbyjs.org/packages/gatsby-plugin-react-helmet-canonical-urls
 * @see https://www.gatsbyjs.org/packages/gatsby-plugin-remove-trailing-slashes
 * @see https://www.gatsbyjs.org/docs/creating-and-modifying-pages/#removing-trailing-slashes
 *
 * Generate RSS.xml
 * rss plugin
 *
 * Generate sitemap
 * sitemap plugin
 */
const SEO = ({ title, description }) => (
  <Helmet title={title}>

    <meta name="description" content={description} />

    {/*<meta property="og:type" content="website" />*/}

    {/*<meta property="og:url" content={route} />*/}

    {/*<meta property="og:title" content={title} />*/}

    {/*<meta property="og:description" content={description} />*/}

  </Helmet>
);

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  // image: PropTypes.string,
  // route: PropTypes.string,
};

SEO.defaultProps = {
  title: "Oberion",
  description: null,
};
