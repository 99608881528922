import React from "react";
import styled from "styled-components";
import { ROUTES } from "./routes";
import { InternalRoute } from "./InternalRoute";
import * as PropTypes from "prop-types";
import OoAvatar from "../element/graphical/OoAvatar";
import thomas from "../element/graphical/thomas-uta-avatar-500px-lowres.jpg";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;
const logoTxt = "OMG-Prototype";

export const Logo = ({ variant, showAvatar }) => {
  if (showAvatar) {
    return (
      <LogoContainer>
        <OoAvatar src={thomas} alt="Thomas Uta" height="4rem" width="4rem" />
        <InternalRoute caption={logoTxt} to={ROUTES.HOME.TO}
                       variant={variant}
                       lightTheme="header" />
      </LogoContainer>
    );
  }
  return (
    <InternalRoute caption={logoTxt} to={ROUTES.HOME.TO}
                   variant={variant}
                   lightTheme="header" />
  );
};
Logo.defaultProps = {
  variant: "h2",
  showAvatar: false,
};
Logo.propTypes = {
  variant: PropTypes.string,
  showAvatar: PropTypes.bool,
};

const NavSt = styled.nav`
  width: 18rem;
  padding: 0 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;
const UlSt = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const NavList = () => {
  return (
    <NavSt>
      {/*<UlSt>*/}
      {/*  <InternalRoute caption={ROUTES.RESUME.CAPTION} to={ROUTES.RESUME.TO}*/}
      {/*                 variant="subtitle1" />*/}
      {/*</UlSt>*/}
      {/*<UlSt>*/}
      {/*  <InternalRoute caption={ROUTES.PORTFOLIO.CAPTION} to={ROUTES.PORTFOLIO.TO}*/}
      {/*                 variant="subtitle1" />*/}
      {/*</UlSt>*/}
      {/*<UlSt>*/}
      {/*  <InternalRoute caption={ROUTES.KONTAKT.CAPTION} to={ROUTES.KONTAKT.TO}*/}
      {/*                 variant="subtitle1" />*/}
      {/*</UlSt>*/}
    </NavSt>
  );
};

