import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { CHANNELS } from "./SocialMediaButton";
import { grey3 } from "../theme";

const DefaultIcon = styled.img`
    box-sizing: border-box;
    display: block;
    height: 1.9rem;
    width: auto;
    margin: 0 auto;
`;

function SocialMediaIcon({ icon, caption }) {
  let CustomizedIcon = DefaultIcon;

  switch (caption) {
    case CHANNELS.TWITTER.name:
      CustomizedIcon = styled(DefaultIcon)` height: 1.87rem; `;
      break;
    case CHANNELS.DISCORD.name:
      CustomizedIcon = styled(DefaultIcon)` 
        height: 2.25rem;
        margin: 0 auto -0.12rem auto;
     `;
      break;
    case CHANNELS.RSS.name:
      CustomizedIcon = styled(DefaultIcon)` filter: invert(100%); `;
      break;
    case CHANNELS.MEDIUM.name:
      CustomizedIcon = styled(DefaultIcon)`
        border: 1px solid ${grey3}; 
        box-sizing: content-box 
      `;
      break;
    default:
      break;
  }

  return (
    <CustomizedIcon
      src={icon}
      alt={caption}
    />
  );
}
SocialMediaIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};
export default SocialMediaIcon;
