import React from "react";
import check from "./check.svg";
import Typography from "@material-ui/core/Typography";
import plus from "./plus.svg";
import minus from "./minus.svg";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 1rem 0;
`;
const Symbol = styled.img`
  height: 2rem;
  padding-right: 1rem;
`;
const CheckElement = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 2.5rem 0;
`;

const txt = [
  "Create your website for FREE",
  "In less than FIVE minutes",
  "Automatically import your game description, GIFs, and screenshots from Steam ",
  "Post once on Steam, Oberion posts it everywhere else",
];


const Check = () => {
  return (
    <Container>
      <div>
        {txt.map((element, index) => (
          <CheckElement key={index}>
            <Symbol src={check} alt="checkmark" />
            <div>
              <Typography variant="body1">{element}</Typography>
            </div>
          </CheckElement>
        ))}
      </div>
    </Container>
  );
};

export default Check;